header{
    background-image: url("./images/header.svg");
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    
    background-position: center center;
}

@media only screen and (max-width: 768px){
    header{ 
        background-position: center left;
    }
    h2{
        font-size: 40px!important
    }
    
    .product p{
        font-size: 17px!important;
    }

    .logo{
        max-width: 100px!important;
    }
}

h1, h2, h3, h4, h5, h6{
    font-family: 'Philosopher', sans-serif!important;
}
.heading{
    
    font-size: 4rem;
    font-weight: bold;
}
.c-main{
    color: #323063!important;
}

p{
    font-family: 'Inter', sans-serif;
}
.contact{
    background-color: #45437B;
}
.rounded-5{
    border-radius: 25px;
}
i{
    color: #E9778E;
    font-size: 25px;
}
a{
    text-decoration: none;
}
.ice-img{
    margin-bottom: -60px;
}
.product{
    background:#DAEBC9;
}

.product p{
    overflow-wrap: break-word;
}

.top-cta{
    background-color: #DAEBC9;
    font-family: 'Inter', sans-serif;
    border: #323063 2px solid;
    border-radius: 13px;
    color: #323063;
    font-weight: bold;
}

.logo{
    max-width: 150px;
}